import React, { useState } from "react"
import { css } from "@emotion/core"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useTranslation } from 'react-i18next'
import legoExchangeImg from "images/lego.png"
import prebidImg from "images/prebid.png"
import tacImg from "images/tac.png"
import nextPageBtn from "images/next-page-button.png"
import SEO from "../components/seo"
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'

const linkStyle = css`
  color: #273C57;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  position:relative;
`

const useStyles = makeStyles(theme => ({
  iconInactive: {
    color: 'silver',
    '&:hover': {
      color: '#E5D3E5',
    }
  },
  iconActive: {
    color: '#273C57'
  }
}));

const Projects = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [projectName, setProjectName] = useState('prebid')
  

  const changeProject = (name) => {
    setProjectName(name)
  }

  const imgSource = {
    "lego": legoExchangeImg,
    "prebid": prebidImg,
    "tac": tacImg
  }

  const projectTechStack = {
    "lego": ['react', 'react-router', 'npm', 'webpack', 'axios'],
    "prebid": ['prebidjs', 'javascript', 'mocha', 'chai'],
    "tac": ['react', 'jquery', 'nodejs', 'html']
  }

  return(
    <div css={css`
      background: #ae68bc;
      height: 100vh;
      display: flex;
      @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        padding-top: 80px;
      } 
    `}>
      <div css={css`
        background-color: white;
        margin-right: 40px;
        width: 100%;
        padding: 3% 4% 3%;
        display: grid;
        grid-template-columns: 60% 40%;
        grid-template-rows: 17% auto 20%;
        grid-row-gap: 30px;
        grid-template-areas:
          "title list"
          "screenshot project-content"
          "next-page next-page";
        @media (min-width: 320px) and (max-width: 480px) {
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3% 6% 7%;
        }
      `}>
        <SEO title="Projects" />
        <h1 css={css`
          grid-area: title;
          align-self: end;
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: calc(1em + 4vw);
            margin: 1.5rem auto;
          }
        `}>
          Projects
        </h1>
        <div css={css`
          grid-area: list;
          align-self: end;
        `}>
          <Icon 
            className={ projectName === 'prebid'? classes.iconActive :classes.iconInactive }
            onClick={(e) => changeProject('prebid')}>
              looks_one
          </Icon>
          <Icon
            className={ projectName === 'lego'? classes.iconActive :classes.iconInactive }
            onClick={(e) => changeProject('lego')}>
              looks_two
          </Icon>
          <Icon
            className={ projectName === 'tac'? classes.iconActive :classes.iconInactive }
            onClick={(e) => changeProject('tac')}>
              looks_3
          </Icon>
        </div>
        <div css={css`
          grid-area: screenshot;
          padding-left: 2vw;
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
            margin-bottom: 15px;
          }
        `}>
          <img 
            src={imgSource[projectName]}
            css={css`
              object-fit: cover;
              width: 90%;
              @media only screen 
                and (min-device-width: 768px) 
                and (max-device-width: 1024px)
                and (-webkit-min-device-pixel-ratio: 2) {
                width: 85%;
                max-height: 80%;
              }
              @media only screen 
                and (min-device-width: 1024px) 
                and (max-device-width: 1366px)
                and (-webkit-min-device-pixel-ratio: 2) {
                width: 85%;
                max-height: 80%;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                width: 78vw;
              }
            `}
            alt="Project Image"
          />
        </div>
  
        <div css={css`
          grid-area: project-content;
          padding-right: 2vw;
          @media (min-width: 1500px) {
            padding-left: 1vw;
            padding-right: 4vw;
          }
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 30px;
          }
        `}>
          <div css={css`
            box-shadow: 12px 10px 18px 3px rgba(0, 0, 0, 0.2);
            width: 80%;
          `}>
            <p css={css`
              background: #273C57;
              color: white;
              text-align: center;
              font-size: calc(1em + 1.1vw);
              font-style: italic;
            `}>
              {t(`projects.${projectName}.name`)}
            </p>
          </div>
          <p css={css`
            text-align: justify;
            padding-top: 7%;
            font-size: 1rem;
          `}>
            {t(`projects.${projectName}.description`).split('\n').map(function(item, key) {
                return (
                  <span key={key}>
                    {item}
                    <br/>
                  </span>
                )
              })
            }
          </p>
          <div css={css`
            padding-top: 9%;
            font-size: 1rem;
            width: 80%;
            @media (min-width: 320px) and (max-width: 480px) {
              width: 90%;
            }
          `}>
            <p>Tech Stack :</p>
            {projectTechStack[projectName].map(name => {
              return (
                <div css={css`
                  text-transform: uppercase;
                  display: inline-block;
                  text-align: center;
                  margin: 12px 10px;
                `}>
                  <img
                    css={css`
                      margin: 0 auto;
                      display: block;
                    `}
                    src={require(`images/${name}.png`)}
                    alt={name}
                  />
                  <span>{name.replace(/-/g, ' ')}</span>
                </div>
              )
            })}
          </div>
        </div>
  
        <div css={css`
            grid-area: next-page;
            align-self: start;
            padding-left: 2vw;
            @media (min-width: 1100px) {
              padding-top: 1vw;
            }
            @media (min-width: 320px) and (max-width: 480px) {
              padding-left: 0;
            }
          `}>
            <AniLink swipe direction="up" to="/contact"
              css={linkStyle}
            >
              <img
                src={nextPageBtn}
                alt="Next Page"
                css={css`
                  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.2));
                `}
              />
              <span css={css` 
                position: absolute;
                width: 150px;
                margin-left: -18%;
                margin-top: 30%; 
              `}>04 {t('navbar.contact')}</span>
            </AniLink>
          </div>
      </div>
    </div>
  )
}

export default Projects
